<template>
    <div class="">
      <rxNavBar
        v-if="androidFlg == false"
        title="员工档案"
        androidOrIOSFlag="true"
      ></rxNavBar>
      <rxNavBar v-else title="员工档案"></rxNavBar>


      <div class="details">
        <div >
          <div class="detailscontent">
            <van-image   fit="contain" :width="40" :height="40" :src="detailsInfo.pic" radius="5px" @click="openImg()" class="head_sculpture"/>
            <div style="font-size: 15px;font-weight: bold;">{{detailsInfo.staffName}}-【{{detailsInfo.employeeNumber}}】</div>
          </div>
        </div>
        <div class="company-name">辽宁海米信息科技有限公司</div>
        <div class="organization-information">个人信息</div>
        <div class="detailscontent">
          <span class="key">性别</span>
          <span class="value">{{detailsInfo.gender==='0'?'女':'男'}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">电话</span>
          <span class="value" >{{detailsInfo.mobile}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">{{detailsInfo.certificateType?detailsInfo.certificateTypeName:'身份证'}}</span>
          <span class="value" >{{detailsInfo.idCard}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">角色</span>
          <span class="value" >{{detailsInfo.roleName}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">户籍地址</span>
          <span class="value" >{{detailsInfo.registeredResidenceAddress}}</span>
        </div>
        <div class="organization-information">入职信息</div>
        <div class="detailscontent">
          <span class="key">状态</span>
          <span class="value">{{detailsInfo.staffStatusName}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">部门</span>
          <span class="value">{{detailsInfo.departmentName}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">职务</span>
          <span class="value">{{detailsInfo.dutyName}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">级别</span>
          <span class="value">{{detailsInfo.dutyLevelName}}</span>
        </div>
        <div class="detailscontent" v-if="detailsInfo.masterName">
          <span class="key">推荐师父</span>
          <span class="value">{{detailsInfo.masterName}}</span>
        </div>
        <div class="detailscontent" v-if="detailsInfo.masterName">
          <span class="key">推荐师父职务</span>
          <span class="value">{{detailsInfo.masterdutyName}}（{{detailsInfo.masterLabelText}}）</span>
        </div>
        <div class="detailscontent">
          <span class="key">入职公司</span>
          <span class="value">{{detailsInfo.companyName}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">入职日期</span>
          <span class="value">{{detailsInfo.entryTime}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">转正日期</span>
          <span class="value">{{detailsInfo.regularTime}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">培训期数</span>
          <span class="value">{{detailsInfo.trainTimes}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">合同到期日</span>
          <span class="value">{{detailsInfo.contractExpiredTime}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">入职来源</span>
          <span class="value">{{detailsInfo.entrySourceName}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">工资卡号</span>
          <span class="value">{{detailsInfo.payCardCode}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">省-市</span>
          <span class="value">{{detailsInfo.province}} - {{detailsInfo.city}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">开户行</span>
          <span class="value">{{detailsInfo.openingBankName}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">支行</span>
          <span class="value">{{detailsInfo.subBank}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">行号</span>
          <span class="value">{{detailsInfo.salesDepartmentNo}}</span>
        </div>
        <div class="organization-information">详细信息</div>
        <div class="detailscontent">
          <span class="key">学历</span>
          <span class="value">{{detailsInfo.education}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">紧急联系人</span>
          <span class="value">{{detailsInfo.emergencyContactPerson}}({{detailsInfo.emergencyContactRelationship}})</span>
        </div>
        <div class="detailscontent">
          <span class="key">紧急联系人电话</span>
          <span class="value">{{detailsInfo.emergencyContactPhone}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">邮箱</span>
          <span class="value">{{detailsInfo.email}}</span>
        </div>
        <div class="detailscontent">
          <span class="key">通讯地址</span>
          <span class="value">{{detailsInfo.address}}</span>
        </div>
        

        <div style="visibility:hidden;">1</div>
        <div class="buttonConunts">

        <div @click="departApply" :class="'bottom-fixed-orderLook1'">申请离职</div> 
        </div>
      </div>

      <!--底部固定栏-->
      <div class="bottom-fixed">
        <div @click="workCheckEvent" :class="'bottom-fixed-orderLook'">考勤</div>
        <div @click="recommendRecordEvent" :class="'bottom-fixed-orderLook'">推荐记录</div>

      </div>
      <van-popup v-model="departApplyShow" position="bottom" :style="{ height: '54%' }" >
        <div class="date-dropDownList">
          <div class="part-inputpart-row" @click="isOperDateShow = !isOperDateShow">
            <span class="maker" :class="{'orangeColor':(departApplyObj.operDateStr!='')}">• </span>
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">离职日期</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="departApplyObj.operDateStr ? 'part-inputpart-row-normaltext':'' ">{{departApplyObj.operDateStr}}</span>
            <img class="part-inputpart-row-right" :class=" isStaffListShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <van-popup v-model="isOperDateShow" position="bottom" >
            <div class="date-dropDownList">
              <van-datetime-picker v-model="departApplyObj.operDate" type="date" title="选择年月日" item-height="35px"
                                   @confirm="operDateConfirm" @cancel="isOperDateShow=false"/>
            </div>
          </van-popup>
          <div class="part-inputpart-row" @click="isReasonShow = !isReasonShow">
            <span class="maker" :class="{'orangeColor':(departApplyObj.reason!='')}">• </span>
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">离职原因</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="departApplyObj.reason ? 'part-inputpart-row-normaltext':'' ">{{departApplyObj.reason}}</span>
            <img class="part-inputpart-row-right" :class=" isReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <van-popup v-model="isReasonShow" position="bottom">
            <van-picker
                show-toolbar
                :columns="quitReasonList"
                @cancel="isReasonShow = false"
                :default-index="departApplyObj.reasonIndex!=''?Number(departApplyObj.reasonIndex):0"
                value-key="dictionaryTitle"
                @confirm="selectReason"
            />
          </van-popup>
          <div class="part-inputpart-row" @click="isStaffListShow = !isStaffListShow">
            <span class="maker" :class="{'orangeColor':(departApplyObj.staffName!='')}">• </span>
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">交接人</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="departApplyObj.staffName ? 'part-inputpart-row-normaltext':'' ">{{departApplyObj.staffName}}</span>
            <img class="part-inputpart-row-right" :class=" isStaffListShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <van-popup v-model="isStaffListShow" position="bottom">
            <div class="searchDiv">
              <van-search
                  placeholder="请输入姓名/电话"
                  v-model="searchName"
                  size="large"
                  @input="initStaffData()"
              />
            </div>
            <van-picker
                show-toolbar
                :columns="handoverStaffList"
                @cancel="isStaffListShow = false"
                :default-index="departApplyObj.staffIndex!=''?Number(departApplyObj.staffIndex):0"
                value-key="staffName"
                @confirm="selectHandoverStaff"
            />
          </van-popup>
          <div class="approveOpinion">
            <p class="yijian" style="font-size: 15px;font-weight: bold">备注</p>
            <div class="text_body">
              <van-field v-model="departApplyObj.remark"  type="textarea" class="mes_body" rows="3"
                         autosize/>
            </div>
          </div>
          <div class="sub_btn">
            <van-button  :class="!checkDepartApply()?'saveButton_Enable':'saveButton_Disable'" size="large" @click="saveDepartApply()">保存
            </van-button>
          </div>
        </div>
      </van-popup>
    </div>
</template>

<script>
import {
  Button,
  DatetimePicker, Field,
  Image as VanImage, ImagePreview, IndexAnchor, IndexBar,
  NavBar,
  Picker,
  Popup, Search,
  Sticky,
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import dialMobile from "../../../components/rongxun/rx-dialMobile/dialMobile";
import {checkAndroid, checkIOS, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {queryBaseData, queryStaff, queryStaffList,addEmploymentApproval} from '../../../getData/getData'
import util from "../../contract/util";
export default {
  name: "personalResume",
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    rxNavBar,
    [Sticky.name]: Sticky,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Search.name]: Search,
  },
  data(){
    return{
      androidFlg: '',
      detailsInfo:{},
      departApplyShow:false,
      departApplyObj:{
        operDate:new Date(),
        operDateStr:'',
        reason_id:'',
        reason:'',
        reasonIndex:0,
        handoverStaff_id:'',
        staffName:'',
        staffIndex:0,
      },
      quitReasonList:[],
      handoverStaffList:[],
      isReasonShow:false,
      isOperDateShow:false,
      isStaffListShow:false,
      searchName:'',
      disabled:false,  //防连点
    }
  },
  mounted() {
    this.initBaseData()
    this.initStaffData()
    this.initStaffDetail()
    this.checkPhoneorMobel()
  },
  methods:{
    workCheckEvent() {
      this.$router.push({
        name: 'workCheckindex',
        query: {
          data: 1
        }
      })
    },
    recommendRecordEvent(){
      this.$router.push({
        name: 'recommendRecord',
        query: {
          id: this.detailsInfo.id
        }
      })
    },
    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      }
      else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    initStaffDetail(){
      let that = this
      let initData = {}
      initData.user_id = getStaffId()
      initData.id = getStaffId()
      queryStaff(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          that.detailsInfo = response.data.data
        })
      })
    },
    //放大图片
    openImg() {
      let arr = []
      arr.push(this.detailsInfo.pic)
      ImagePreview({
        images: arr,
        // startPosition: j,
        onClose() {},
      })
    },
    departApply(){
      this.departApplyObj.operDateStr = util.dateToString(this.departApplyObj.operDate)
      this.departApplyShow = true
    },

    operDateConfirm(item,index){
      this.isOperDateShow = false
      this.departApplyObj.operDateStr = util.dateToString(item)
    },
    selectReason(item,index){
      this.departApplyObj.reason_id = item.id
      this.departApplyObj.reason = item.dictionaryTitle
      this.departApplyObj.reasonIndex = index
      this.isReasonShow = false
    },
    selectHandoverStaff(item,index){
      console.log(item)
      this.departApplyObj.handoverStaff_id = item.id
      this.departApplyObj.staffName = item.staffName.split(" ")[0]
      this.departApplyObj.staffIndex = index
      this.isStaffListShow = false
    },


    checkDepartApply(){
      if(this.departApplyObj.handoverStaff_id!='' && this.departApplyObj.reason_id!='' && this.departApplyObj.operDate!=undefined){
        return false
      }
      return true
    },
    saveDepartApply(){
      if(this.disabled){
        return;
      }
      if(this.checkDepartApply()){
        return
      }
      let that = this
      let initData = {}
      initData.addStaff_id = getStaffId()
      initData.staff_id = this.detailsInfo.id
      initData.approvalType = 1
      initData.operDate = this.departApplyObj.operDateStr
      initData.reason_id = this.departApplyObj.reason_id
      initData.handoverStaff_id = this.departApplyObj.handoverStaff_id
      initData.remark = this.departApplyObj.remark
      this.disabled = true
      addEmploymentApproval(initData).then(function (response) {
        that.disabled = false
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertOK(that)
          that.departApplyShow = false
        })
      })
    },


    //查询员工
    initStaffData:function() {
      var that = this
      let initData = {}
      initData.searchName = that.searchName
      queryStaffList(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.handoverStaffList = response.data.data.data
        })
      })
    },
    initBaseData(){
      let that = this
      let initData = {}
      initData.dbName = ['quitReason']
      // initData.fdName = ['MAINTRIALPLANRECORDSTATUS']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.quitReasonList = response.data.data.quitReason;
        })
      })
    }
  },
}
</script>

<style scoped lang="less">
.details{
  .detailscontent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #f2f2f2;
  }
  background:white;
  margin-bottom: 70px;

  .van-image{
    //position:absolute;
    //top:10px;
    //left:20px;
    //z-index:1000;
    border-radius: 50%;
  }
  .van-cell{
    position:relative;
    left:55px;
    top:-5px;
    font-size:14px;
    font-weight: bold;
    padding:15px;
  }
  .van-cell__label{
    margin-top:0;
    font-size: 7px;
    font-weight: normal;
  }
  .company-name{
    font-size:11px;
    padding:15px 15px;
    color:#3da5ef;
    border-bottom: 1px solid #f2f2f2;
  }
  .organization-information{
    font-size:14px;
    font-weight: bold;
    padding:15px 15px;
    border-bottom: 1px solid #f2f2f2;
  }
  .key{
    font-size:13px;
    color:grey;
    //margin:8px 20px;
  }
  .value{
    font-size:13px;
  }
}

.bottom-fixed{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(250, 250, 250, 1);
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
}
.bottom-fixed-orderLook{
  width: 91px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(to right,#7cc7ff,#3596fd);
  box-shadow: 0px 1px 1px 0px rgba(0, 115, 202, 0.5);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Semibold;
}
.bottom-fixed-orderLook1 {
  width: 96%;
  height: 40px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(to right,#7cc7ff,#3596fd);
  box-shadow: 0px 1px 1px 0px rgba(0, 115, 202, 0.5);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Semibold;
}
.buttonConunts {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-dropDownList{
  padding: 0.3rem;
}
/*选择名称的div*/
.part-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  line-height: 1rem;
  height: 50px;
  margin: 0px auto;
  align-items: center;
  /*圆点样式---灰色*/
  .maker {
    color: #999999;
    padding-left: 14px;
    padding-right: 8px;
    font-size: 28px;
  }
  .orangeColor{
    color: rgba(255, 93, 59, 1);
  }
  .part-inputpart-row-header {
    font-weight: bold;
    font-size: 15px;
    width: 3rem;
  }
  /*竖线样式*/
  .content-divide {
    font-size: 14px;
    color: #efefef;
    margin: 0 5px 0 5px;
  }
  .part-inputpart-row-graytext {
    color: #d9d9d9;
    width: 6rem;
    font-size: 14px;
    padding-left: 0.3rem;
  }
  .part-inputpart-row-normaltext{
    color: black;
  }
  .part-inputpart-row-right-downArrow{
    width: 9px;
    height: 9px;
    margin-right: 15px;
  }
  /*选择三角图标的向上向下样式*/
  .part-inputpart-row-right-upArrow{
    width: 9px;
    height: 9px;
    /*transform: rotateX(180deg);*/
    margin-right: 15px;
  }
}
.approveOpinion{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.4rem;
  background-color: white;
  border-radius: 0.2rem;
  .yijian{
    font-size: 15px;
  }
  .text_body {
    //padding: 15px;
    margin-top: 0.4rem;
    .mes_body {
      border-radius: 8px;
      padding: 0.2rem;
      background-color: rgb(250, 250, 250);
    }
  }
}
.sub_btn {
  margin: 0.8rem auto 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  margin: 0.1rem 0 0.3rem;
}
.saveButton_Disable{
  display: block;
  background-color: rgba(184, 184, 184, 0.2);
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  margin: 0.1rem;
}
</style>